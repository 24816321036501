import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

// testing out the other syntax for naming this function thing 
// and then exporting it as default below
const About = ({ data, pageContext }) => {
	return (
		<Layout> 
			<div className="row">
				<div className="col d-flex justify-content-center">
					<div className="post--inner"
						 style={{
							borderColor:  data.site.siteMetadata.defaultAccentColor,
							borderWidth: "3px",
							width: "720px"
						 }}>
						<p>👋</p>
						<p>
							Hi! Welcome to my internet area.
							For the past five years I’ve been building <a href="https://www.relevant.healthcare">Relevant</a>.
							 Before that I spent 18 months writing an <a href="/posts/pallets">article about shipping pallets</a>.
						</p>

						<p>
							You can occasionally find me on <a href="https://twitter.com/peashutop">Twitter</a>, <a href="https://www.instagram.com/peashutop/">Instagram</a>, <a href="https://www.goodreads.com/user/show/202829-jacob">Goodreads</a>, <a href="https://github.com/peashutop/">GitHub</a>, or the odious <a href="https://www.linkedin.com/in/jacob-hodes-239a1619/">LinkedIn</a>.
							Email: <a href="mailto:erg35t75zz4@peashutop.com">erg35t75zz4@peashutop.com</a>.
						</p>
						
						<p>
							N.b. I’m not a lawyer or equities trader; that’s the other Jacob Hodes, who currently <a href="https://www.brownadvisory.com/jacob-hodes">works at Brown Advisory</a>. <em>Update May 2020:</em> We found another one! Meet the young <a href="https://www.linkedin.com/in/jacobhodes/">
							Australian upstart Jacob Hodes</a>.
						</p>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default About;

export const query = graphql`
	query {
	    site {
    		siteMetadata {
      			defaultAccentColor
    		}
		}
	}
`
